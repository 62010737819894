/* VIDEO PLAYER */

.rh5v-DefaultPlayer_video {
    border-radius: 25px !important;
}

.video-row {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

video:-webkit-full-screen {
    width: 100%;
    height: 100%;
    max-height: 100%;
}