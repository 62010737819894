/* MAIN COMPONENT */

.main {
    width: 100%;
    height: 100vh;
}

.bg-video {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.content h1 {
    font-size: 700%;
    text-transform: uppercase;
}

#pro-txt {
    color: #41ad49;
}

.strikeout {
    line-height: 1em;
    position: relative;
}
.strikeout::after {
    border-bottom: 0.125em solid #41ad49;
    border-radius: 25px;
    content: "";
    left: 0;
    margin-top: calc(0.125em / 2 * -1);
    position: absolute;
    right: 0;
    top: 50%;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.3);
}

/* CTA BUTTON */

.cta-btn {
    padding: 20px 100px !important;
    font-size: 300% !important;
    margin: 50px 0 0 0 !important;
    text-transform: uppercase;
    background-color: #41ad49 !important;
    font-weight: lighter !important;
    cursor: pointer !important;
}

.logo {
    position: absolute;
    top: 45px; /* Adjust as necessary */
    right: 20px; /* Adjust as necessary */
    width: 300px; /* Adjust as necessary */
    height: auto;
    z-index: 1000; /* Ensures the logo stays on top of other elements */
}